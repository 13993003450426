import { ReactNode } from 'react';
import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { UnansweredQuestion } from '../../entities/questions';
import { EquipmentSerializable } from '@/src/equipment/types';
import { Model } from '@/src/entities/model';
import { Listing } from '@/src/entities/listing';
import { ListingSerializable } from '@/src/listings/types';

export enum GlobalModalTypes {
  EMPTY = 'Empty',
  ANSWER_LISTING_QUESTIONS = 'AnswerListingQuestions',
  CONFIRMATION = 'Confirmation',
  REQUEST_PRICE = 'RequestPrice',
  EMS_ADD_EQUIPMENT = 'EmsAddEquipment',
  MAKE_OFFER = 'MakeOffer',
  MESSAGE_SELLER = 'MessageSeller',
  FIND_ONE = 'FindOne',
  CONTACT_SALES = 'ContactSales',
  REQUEST_DETAILS = 'RequestDetails',
  CREATE_ACCOUNT = 'CreateAccount',
  LOGIN = 'Login',
  EXISTING_ACCOUNT_RESET_PASSWORD = 'ExistingAccountResetPassword',
  MOBILE_MESSAGE_CENTER = 'MobileMessageCenter'
}

export interface AnswerListingQuestionsProps {
  listingKey: string;
  questions: UnansweredQuestion[];
  onClose: () => void;
}

export interface ConfirmationModalProps {
  title: string;
  description: ReactNode;
  confirmText?: string;
  cancelText?: string;
  onClose: () => void;
  onConfirm: () => Promise<void>;
}

export interface FindOneProps {
  model: { id: number } | Model;
}
export interface RequestPriceProps {
  equipment: EquipmentSerializable;
}

export interface RequestDetailsProps {
  listing: Listing | ListingSerializable;
}

export interface MakeOfferProps {
  listing: Listing | ListingSerializable;
}

export interface MessageSellerProps {
  listing: Listing | ListingSerializable;
}

export interface CreateAccountProps {
  leadInfo: {
    email: string;
    key: string;
    first_name?: string;
    last_name?: string;
    company?: string;
    phone?: string;
  };
}

export interface ModalProps {
  answerListingQuestions?: AnswerListingQuestionsProps;
  confirmation?: ConfirmationModalProps;
  findOne?: FindOneProps;
  requestPrice?: RequestPriceProps;
  requestDetails?: RequestDetailsProps;
  makeOffer?: MakeOfferProps;
  createAccount?: CreateAccountProps;
  existingAccountResetPassword?: { email: string };
  messageSeller?: MessageSellerProps;
}

export interface ModalState {
  modalOpen: boolean;
  modalType: GlobalModalTypes;
  modalProps: ModalProps;
}

export const initialState: ModalState = {
  modalOpen: false,
  modalType: GlobalModalTypes.EMPTY,
  modalProps: {}
};

export const slice = createSlice({
  name: 'modal',
  initialState,
  reducers: {
    openModal: (state, action: PayloadAction<{ modalType: GlobalModalTypes; modalProps: ModalProps }>) => {
      state.modalOpen = true;
      state.modalType = action.payload.modalType;
      state.modalProps = { ...state.modalProps, ...action.payload.modalProps };
    },
    closeModal: () => initialState,
    resetState: () => initialState
  }
});

export const { openModal, closeModal, resetState } = slice.actions;

export default slice.reducer;
