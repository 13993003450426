import { colors } from '@/src/design/designConstants';
import { Divider, Stack } from '@mui/material';
import { getAllMessageThreads, getMessagesLoading } from '../../selectors';
import { useAppDispatch, useAppSelector } from '@/src/store';
import { MessageSummary } from '../../messageSummary';
import { fetchAllMessages } from '../../thunks';
import { useEffect } from 'react';
import { MoovLoader } from '@/src/bits/forms/loaders';
import { MessagesNullState } from '../../nullState';
import { useInterval } from '@/src/hooks/useInterval';
import { deselectMessage } from '../../slice';

export const MobileMessages = () => {
  const dispatch = useAppDispatch();
  const { loading, initialized } = useAppSelector(getMessagesLoading);
  const { setIntvl } = useInterval();
  const allMessageThreads = useAppSelector(getAllMessageThreads);

  useEffect(() => {
    //Fetch initial messages
    dispatch(fetchAllMessages({ setLoadFlags: true, selectOnLoad: false }));

    //Poll for new messages 2 minutes
    const interval = 2 * 60000;
    setIntvl(() => {
      dispatch(fetchAllMessages({ setLoadFlags: false, selectOnLoad: false }));
    }, interval);

    return () => {
      dispatch(deselectMessage());
    };
  }, []);

  if (loading || !initialized) {
    return (
      <Stack variant="center" width="100%" height="100%">
        <MoovLoader isLoading />
      </Stack>
    );
  }

  if (initialized && !allMessageThreads.length) {
    return <MessagesNullState />;
  }

  return (
    <Stack data-testid="messaging-center-thread-list" overflow="scroll" bgcolor={colors.surface.white}>
      <Stack width="100%" divider={<Divider />}>
        {allMessageThreads.map((thread) => {
          return <MessageSummary key={`listing-message-${thread.key}`} messageThread={thread} />;
        })}
      </Stack>
      <Divider />
    </Stack>
  );
};
