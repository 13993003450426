import { useState } from 'react';
import { InputLabel, Stack, Typography } from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers-pro';
import * as yup from 'yup';
import WorkflowModal from '@/src/bits/modals/workflowModal';
import { useLocalizer } from '@/src/localization';
import { closeModal } from '@/src/state/globalModal/slice';
import { ERROR_MESSAGES } from '@/src/constants';
import { useAppDispatch, useAppSelector } from '@/src/store';
import { useFormValidation } from '@/src/hooks/useFormValidation';
import { getContactFormSchema } from '@/src/validationSchemas/lead';
import { ContactFormValues } from '../../../forms/contactForm';
import { getModalState } from '@/src/state/globalModal/selectors';
import { colors, spacing } from '@/src/design/designConstants';
import { LabeledTextField } from '@/src/design/bytes/labeledTextField';
import { CheckboxByte } from '@/src/design/bytes/checkbox';
import { openError } from '@/src/state/banner/slice';
import { captureException } from '@sentry/nextjs';
import { getCurrentUser } from '@/src/state/user/selectors';
import { ListingForms, submitListingForm } from '@/src/state/forms/thunks';
import { LabeledCurrencyInput } from '@/src/design/bytes/labeledCurrencyInput';
import { CustomEvents } from '@/src/hooks/useFullStory/customEvents';
import { useFullStory } from '@/src/hooks/useFullStory/useFullStory';

export enum ModalSteps {
  OFFER = 1
}

type MakeOfferPossibleSteps = ModalSteps.OFFER;

export interface MakeOfferFormValues extends ContactFormValues {
  is_offer: 1;
  offer_amount: string;
  destination: string;
  required_by: Date | null;
  insurance: boolean;
  logistics: boolean;
  appraisal: boolean;
  installation: boolean;
  listing_key: string;
  model_id: number | null;
}

export const AuthMakeOfferModal = () => {
  const dispatch = useAppDispatch();
  const loc = useLocalizer();
  const {
    modalProps: { makeOffer }
  } = useAppSelector(getModalState);
  const user = useAppSelector(getCurrentUser);
  const [currentStep, setCurrentStep] = useState<MakeOfferPossibleSteps>(ModalSteps.OFFER);
  const { fullStoryEvent } = useFullStory();

  const initialValues: MakeOfferFormValues = {
    email: user?.email,
    first_name: user?.first_name,
    last_name: user?.last_name,
    company: user?.user_company?.name || '',
    phone: user?.phone,
    contact_preference: user?.contact_preference,
    contact_handle: user?.contact_handle,
    is_offer: 1,
    offer_amount: '',
    destination: '',
    required_by: null,
    insurance: false,
    logistics: false,
    appraisal: false,
    installation: false,
    listing_key: '',
    model_id: null
  };
  const formValidationHook = useFormValidation(
    initialValues,
    getContactFormSchema(loc).shape({
      is_offer: yup.number(),
      offer_amount: yup.string(),
      destination: yup.string(),
      insurance: yup.boolean(),
      logistics: yup.boolean(),
      appraisal: yup.boolean(),
      installation: yup.boolean()
    })
  );

  if (!makeOffer?.listing || !user?.id) return null;

  const steps: Record<
    ModalSteps,
    {
      title: string;
      actionButtonDisabled: boolean;
      actionButtonContent: string;
      actionButtonSubmit: () => Promise<void>;
      actionButtonId?: string;
      dismissButtonId?: string;
    }
  > = {
    [ModalSteps.OFFER]: {
      title: loc.Listing.MakeOffer,
      actionButtonDisabled: false,
      actionButtonContent: loc.Forms.Submit,
      actionButtonSubmit: async () => {
        fullStoryEvent(CustomEvents.listing.formSubmit, { type: 'MakeOffer' });
        return dispatch(
          submitListingForm(
            {
              from_url: window.location.href,
              company: formValidationHook.formValues.company,
              first_name: formValidationHook.formValues.first_name,
              last_name: formValidationHook.formValues.last_name,
              email: formValidationHook.formValues.email,
              phone: formValidationHook.formValues.phone,
              contact_preference: formValidationHook.formValues.contact_preference,
              contact_handle: formValidationHook.formValues.contact_handle,
              is_offer: 1,
              offer_amount: formValidationHook.formValues.offer_amount,
              destination: formValidationHook.formValues.destination,
              required_by: formValidationHook.formValues.required_by,
              insurance: formValidationHook.formValues.insurance,
              logistics: formValidationHook.formValues.logistics,
              appraisal: formValidationHook.formValues.appraisal,
              installation: formValidationHook.formValues.installation,
              listing_key: makeOffer.listing.key,
              listing_id: makeOffer.listing.id,
              model_id: makeOffer.listing.model.id,
              form_name: ListingForms.MAKE_OFFER
            },
            makeOffer.listing.key
          )
        )
          .then(() => {
            dispatch(closeModal());
          })
          .catch((e) => {
            dispatch(
              openError({
                error: ERROR_MESSAGES.ERROR_PROCESSING_REQUEST
              })
            );
            captureException(e);
          });
      },
      actionButtonId: 'listing-make-offer-auth-step-1-submit-button',
      dismissButtonId: 'listing-make-offer-auth-step-1-dismiss-button'
    }
  };

  const step1 = (
    <>
      <Stack spacing={spacing['xl']}>
        <Stack mb={spacing['3xl']}>
          <Typography variant="body1" color={colors.smokeyGray[500]}>
            {/* TODO: Translations */}
            Provide an offer and timeline below, if any additional services are required we can get started on preparing a quote.
          </Typography>
        </Stack>
        <LabeledCurrencyInput
          inputProps={{ 'data-testid': 'make-offer-offer-amount' }}
          value={formValidationHook.formValues.offer_amount}
          onChange={(e) => formValidationHook.setFieldValue('offer_amount', e.target.value)}
          variant="outlined"
          label={loc.Modals.OfferAmount}
          required
        />
        <LabeledTextField
          inputProps={{ 'data-testid': 'make-offer-destination' }}
          value={formValidationHook.formValues.destination}
          onChange={(e) => formValidationHook.setFieldValue('destination', e.target.value)}
          variant="outlined"
          label="Destination Address"
          placeholder="Destination"
        />
        <Stack>
          {/* TODO: Translations */}
          <InputLabel>Required By</InputLabel>
          <DatePicker
            value={formValidationHook.formValues.required_by}
            onChange={(v) => formValidationHook.setFieldValue('required_by', v)}
          />
        </Stack>
        <InputLabel>Do you require any of the following services?</InputLabel>
        <CheckboxByte
          data-testid="make-offer-checkbox-insurance"
          checked={!!formValidationHook.formValues.insurance}
          onChange={(checked) => {
            formValidationHook.setFieldValue('insurance', checked);
          }}
          label="Insurance"
        />
        <CheckboxByte
          data-testid="make-offer-checkbox-logistics"
          checked={!!formValidationHook.formValues.logistics}
          onChange={(checked) => {
            formValidationHook.setFieldValue('logistics', checked);
          }}
          label="Logistics"
        />
        <CheckboxByte
          data-testid="make-offer-checkbox-appraisal"
          checked={!!formValidationHook.formValues.appraisal}
          onChange={(checked) => {
            formValidationHook.setFieldValue('appraisal', checked);
          }}
          label="Appraisal"
        />
        <CheckboxByte
          data-testid="make-offer-checkbox-installation"
          checked={!!formValidationHook.formValues.installation}
          onChange={(checked) => {
            formValidationHook.setFieldValue('installation', checked);
          }}
          label="Install Assistance"
        />
      </Stack>
    </>
  );

  return (
    <WorkflowModal
      modalOpen
      closeModal={() => dispatch(closeModal())}
      totalSteps={1}
      currentStep={currentStep}
      setCurrentStep={setCurrentStep}
      {...steps[currentStep]}
      actionButtonId={steps[currentStep].actionButtonId ?? 'modal-action-button'}
      dismissButtonId={steps[currentStep].dismissButtonId ?? 'modal-dismiss-button'}
    >
      <Stack minHeight={{ d: '600px' }} width={{ d: '600px' }}>
        {currentStep === ModalSteps.OFFER && step1}
      </Stack>
    </WorkflowModal>
  );
};
