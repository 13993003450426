import { useState } from 'react';
import { SetModal, SwapInModal } from '../../entities/common';
import { useTimeout } from '../useTimeout';

export const useModal = (setModal: SetModal) => {
  const { setTO } = useTimeout();
  const [isOpen, setIsOpen] = useState(true);

  const onClose = () => {
    setIsOpen(false);
    setTO(() => setModal(null), 225);
  };

  const swapInModal: SwapInModal = (modal: JSX.Element) => {
    setIsOpen(false);
    setTO(() => setModal(modal), 125);
  };

  return {
    isOpen,
    onClose,
    swapInModal
  };
};
