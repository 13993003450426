import { useEffect, useState } from 'react';
import { ObjectSchema, ValidationError } from 'yup';

export interface FormValidationHook<T> {
  formValues: T;
  formTouchedState: Record<string, boolean>;
  formErrorState: Record<string, string>;
  submitted: boolean;
  setFieldValue: (field: string, value: unknown) => void;
  setFieldError: (field: string, message: string) => void;
  touchField: (field: string) => void;
  validateField: (field: string, value: unknown) => void;
  validateForm: () => Promise<boolean>;
  onFieldBlur: (field: string) => void;
  isFieldErrored: (field: string) => boolean;
  getFieldError: (field: string) => string | undefined;
  isFormValid: () => boolean;
}

/**
 * This is a crappy placeholder hook for form validation that should
 * be removed once we properly implement form validation library.
 * TODO: END-357 Refactor this to use keyof T instead of a generic string
 */
export const useFormValidation = function <T extends object>(
  defaultFormValues: T,
  validationSchema: ObjectSchema<any>
): FormValidationHook<T> {
  const [formValues, setFormValues] = useState<T>(defaultFormValues);
  const [submitted, setSubmitted] = useState(false);
  const [formErrorState, setFormErrorState] = useState<Record<string, string> | Record<string, never>>({});
  const [formTouchedState, setFormTouchedState] = useState<Record<string, boolean> | Record<string, never>>({});

  useEffect(() => {
    // Run form validation on mount so that we can know if
    // the form is valid before the user has interacted with it
    validateForm(false);
  }, []);

  const setFieldValue = (field: string, value: unknown) => {
    setFormValues((prev) => ({
      ...prev,
      [field]: value
    }));
    if (validationSchema.fields[field] !== undefined) {
      validateField(field, value);
    }
  };

  const setFieldError = (field: string, message: string) => {
    setFormErrorState((prev) => ({
      ...prev,
      [field]: message
    }));
  };

  const touchField = (field: string) => {
    setFormTouchedState((prev) => ({
      ...prev,
      [field]: true
    }));
  };

  const validateField = (field: string, value: unknown) => {
    let newValue = '';

    validationSchema
      .validateAt(field, { ...formValues, [field]: value })
      .catch((err) => {
        newValue = err.message;
      })
      .finally(() => {
        setFormErrorState((prev) => ({
          ...prev,
          [field]: newValue
        }));
      });
  };

  const onFieldBlur = (field: string) => {
    validateField(field, formValues[field as keyof T]);
    touchField(field);
  };

  const isFieldErrored = (field: string): boolean => {
    return (submitted || !!formTouchedState[field]) && !!formErrorState[field];
  };

  const getFieldError = (field: string): string | undefined => {
    return isFieldErrored(field) ? formErrorState[field] : undefined;
  };

  const isFormValid = (): boolean => {
    return Object.values(formErrorState).every((errMsg) => errMsg === '');
  };

  const validateForm = async (submitted: boolean = true): Promise<boolean> => {
    setSubmitted(submitted);

    return validationSchema
      .validate(formValues, { abortEarly: false })
      .then(() => {
        setFormErrorState({});
        return true;
      })
      .catch((errs) => {
        const newFormErrorState: Record<string, string> = {};

        errs.inner.forEach((error: ValidationError) => {
          if (!error.path) return;
          newFormErrorState[error.path] = error.message;
        });

        setFormErrorState(newFormErrorState);
        return false;
      });
  };

  return {
    formValues,
    formTouchedState,
    formErrorState,
    submitted,
    setFieldValue,
    setFieldError,
    touchField,
    validateField,
    validateForm,
    onFieldBlur,
    isFieldErrored,
    getFieldError,
    isFormValid
  };
};
