import { FC } from 'react';
import routes from '../../../routes';
import { useLocalizer } from '../../../localization';
import { SearchBar } from '../searchBar';
import { Button, Divider, Link, Stack, Typography } from '@mui/material';
import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Container from '@mui/material/Container';
import { breakpoints, colors, spacing } from '@/src/design/designConstants';
import { useSelector } from 'react-redux';
import { userIsInternal } from '@/src/state/user/selectors';
import { InternalUserBanner } from '../internalUserBanner';
import { LanguageDropdown } from './languageDropdown';
import { MoovLogo } from '../moovLogo';
import { LearnMore } from '../about';
import { Account } from '../accountSection';
import { Exclusives } from '../exclusives';
import { useUser } from '@/src/hooks/useUser';
import { CategorySubHeader } from './categorySubheader';
import { MaintenanceBanner } from '../maintenanceBanner';
import { AnnouncementBanner } from '../announcementBanner';

export interface DesktopHeaderProps {
  onLoginClick: () => void;
}

export const DesktopHeader: FC<DesktopHeaderProps> = ({ onLoginClick }) => {
  const isInternalUser = useSelector(userIsInternal);
  const loc = useLocalizer();
  const { logoutUser } = useUser();

  return (
    <>
      <AppBar
        position="fixed"
        sx={{ zIndex: (theme) => theme.zIndex.drawer + 1, backgroundColor: colors.surface.white }}
        data-testid="desktop-layout-header"
      >
        <MaintenanceBanner />
        <AnnouncementBanner />
        <Container sx={{ backgroundColor: colors.surface.white, minWidth: breakpoints.d }}>
          <Toolbar disableGutters>
            <Stack direction="column" width={'100%'}>
              {isInternalUser && <InternalUserBanner />}
              <Stack direction="row">
                <Stack direction="row" spacing={spacing.md} justifyContent={'space-between'} alignItems="center" sx={{ width: '100%' }}>
                  <MoovLogo {...{ variant: 'default' }} />

                  {/* NAV BAR ITEMS */}
                  <div className="flex flex-1 items-center gap-x-5">
                    <div className="max-w-[1100px] flex-1">
                      <SearchBar />
                    </div>

                    {/* NAV LINKS */}
                    <Stack direction="row" spacing={0} alignItems={'center'} gap={spacing.xs}>
                      <LearnMore />

                      <Link underline="none" href={routes.marketplace()} color="secondary" key={'browse-link'}>
                        <Button variant="text" color="secondary">
                          <Typography variant={'body1'} textTransform={'uppercase'} fontWeight="600">
                            {loc.Common.Browse}
                          </Typography>
                        </Button>
                      </Link>

                      <Exclusives />
                    </Stack>
                  </div>
                  <Stack direction="row" spacing={spacing.md}>
                    <Account
                      {...{
                        onLoginClick,
                        onLogoutSubmit: async () => {
                          await logoutUser();
                        }
                      }}
                    />
                    <LanguageDropdown loc={loc} />
                  </Stack>
                </Stack>
              </Stack>
            </Stack>
          </Toolbar>
        </Container>

        <>
          <Divider />
          <CategorySubHeader />
        </>
      </AppBar>
    </>
  );
};
