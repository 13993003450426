import { RequiredLabel } from '../../bits/requiredLabel';
import { FormControl, FormLabel, TextField, TextFieldProps, TextFieldVariants } from '@mui/material';

export type LabeledTextFieldProps<Variant extends TextFieldVariants = TextFieldVariants> = TextFieldProps<Variant> & {
  label: string;
  value: string;
};

export const LabeledTextField = function <Variant extends TextFieldVariants = TextFieldVariants>(props: LabeledTextFieldProps<Variant>) {
  return (
    <FormControl error={props.error}>
      <FormLabel>
        {props.label}
        {props.required && <RequiredLabel error={props.error} />}
      </FormLabel>
      <TextField {...{ ...props, label: undefined }} />
    </FormControl>
  );
};
