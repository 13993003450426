import { useSelector } from 'react-redux';
import { getCurrentUser } from '../../state/user/selectors';
import { AppState } from '../../store';
import { BooleanFlag, useBooleanFeatureFlag } from '../featureFlags';

export enum FEATURE_NAME {
  LISTINGS = 'listings',
  EMS = 'ems',
  REMARKETING = 'remarketing',
  LOGISTICS_CALCULATOR = 'logisticsCalculator',
  MESSAGES = 'messaging'
}

export const useFeatures = () => {
  const user = useSelector(getCurrentUser);
  const userLoaded = useSelector((state: AppState) => state.user.loaded);
  const userError = useSelector((state: AppState) => state.user.error);
  const userUnauth = useSelector((state: AppState) => state.user.unauthenticated);

  return {
    [FEATURE_NAME.LISTINGS]: { ready: userLoaded || userError || userUnauth, value: !user?.user_company.is_ems },
    [FEATURE_NAME.EMS]: { ready: userLoaded || userError || userUnauth, value: user?.user_company.is_ems },
    [FEATURE_NAME.REMARKETING]: { ready: true, value: useBooleanFeatureFlag(BooleanFlag.Remarketing) },
    [FEATURE_NAME.LOGISTICS_CALCULATOR]: { ready: true, value: useBooleanFeatureFlag(BooleanFlag.LogisticsCalculator) },
    [FEATURE_NAME.MESSAGES]: { ready: true, value: useBooleanFeatureFlag(BooleanFlag.Messaging) }
  };
};
