import { useRouter } from 'next/router';
import { useContext, useEffect } from 'react';
import { ROUTE_PATHNAMES } from '../../routes';
import { SidebarDispatch, SidebarStore } from '../../state/sidebar/context';

const SIDEBAR_PAGES = [
  ROUTE_PATHNAMES._error,
  ROUTE_PATHNAMES.buy,
  ROUTE_PATHNAMES.buyOrders,
  ROUTE_PATHNAMES.buyPicks,
  ROUTE_PATHNAMES.buySuggested,
  ROUTE_PATHNAMES.category,
  ROUTE_PATHNAMES.categoryAll,
  ROUTE_PATHNAMES.dashboard,
  ROUTE_PATHNAMES.magiq,
  ROUTE_PATHNAMES.magiqExpired,
  ROUTE_PATHNAMES.magiqOpportunity,
  ROUTE_PATHNAMES.make,
  ROUTE_PATHNAMES.makeAll,
  ROUTE_PATHNAMES.marketplace,
  ROUTE_PATHNAMES.model,
  ROUTE_PATHNAMES.listing,
  ROUTE_PATHNAMES.listingEdit,
  ROUTE_PATHNAMES.news,
  ROUTE_PATHNAMES.notFound,
  ROUTE_PATHNAMES.requests,
  ROUTE_PATHNAMES.search,
  ROUTE_PATHNAMES.sell,
  ROUTE_PATHNAMES.equipmentEdit,
  ROUTE_PATHNAMES.sellListings,
  ROUTE_PATHNAMES.sellEquipment,
  ROUTE_PATHNAMES.sellSold,
  ROUTE_PATHNAMES.settlement,
  ROUTE_PATHNAMES.settlements,
  ROUTE_PATHNAMES.suggested,
  ROUTE_PATHNAMES.unsubscribe,
  ROUTE_PATHNAMES.exclusives,
  ROUTE_PATHNAMES.exclusivesAll,
  ROUTE_PATHNAMES.createListing,
  ROUTE_PATHNAMES.messages
];

export const useSidebar = () => {
  const state = useContext(SidebarStore);
  const actions = useContext(SidebarDispatch);
  const router = useRouter();

  // hide or show sidebar based on page
  useEffect(() => {
    if (SIDEBAR_PAGES.includes(router.pathname as ROUTE_PATHNAMES)) {
      actions.unhideSidebar();
    } else {
      actions.hideSidebar();
    }
  }, [router.pathname]);

  const toggleSidebar = () => {
    if (state.open) {
      actions.closeSidebar();
    } else {
      actions.openSidebar();
    }
  };
  return {
    ...state,
    toggleSidebar,
    closeSidebar: actions.closeSidebar,
    endTransition: actions.endTransition
  };
};
