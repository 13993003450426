import type { Locale } from '../../localization/types';
import { getDefinitions } from '../../localization/utils';
import translations from './index.loc.json';

/**
 *
 */
export class AboutLocalizer {
  private definitions;

  constructor(locale: Locale) {
    this.definitions = getDefinitions(translations, locale);
  }

  public get BuyOrSellWithoutHassle(): string {
    return this.definitions.BuyOrSellWithoutHassle;
  }

  public get ContinuesToExpand(): string {
    return this.definitions.ContinuesToExpand;
  }

  public get Founding(): string {
    return this.definitions.Founding;
  }

  public get GlobalDigitalMarketPlaceGrows(): string {
    return this.definitions.GlobalDigitalMarketPlaceGrows;
  }

  public get History(): string {
    return this.definitions.History;
  }

  public get HowWeGotHere(): string {
    return this.definitions.HowWeGotHere;
  }

  public get InternationalTeam(): string {
    return this.definitions.InternationalTeam;
  }

  public get MoovIsBuildingSecondaryMarket(): string {
    return this.definitions.MoovIsBuildingSecondaryMarket;
  }

  public get MoovRaisesSeedFunding(): string {
    return this.definitions.MoovRaisesSeedFunding;
  }

  public get MoovRaisesSeriesA(): string {
    return this.definitions.MoovRaisesSeriesA;
  }

  public get MoovWasFounded(): string {
    return this.definitions.MoovWasFounded;
  }

  public get OurMission(): string {
    return this.definitions.OurMission;
  }

  public get OurMissionIsTo(): string {
    return this.definitions.OurMissionIsTo;
  }

  public get OurValues(): string {
    return this.definitions.OurValues;
  }

  public get OurVision(): string {
    return this.definitions.OurVision;
  }

  public get OurVisionIsTo(): string {
    return this.definitions.OurVisionIsTo;
  }

  public get SeedAngel(): string {
    return this.definitions.SeedAngel;
  }

  public get SeriesA(): string {
    return this.definitions.SeriesA;
  }

  public get SmartMatchTechnology(): string {
    return this.definitions.SmartMatchTechnology;
  }

  public get SomeOfOurValues(): string {
    return this.definitions.SomeOfOurValues;
  }

  public get StevenAndMaxamFound(): string {
    return this.definitions.StevenAndMaxamFound;
  }

  public get SupportYouNeed(): string {
    return this.definitions.SupportYouNeed;
  }

  public get TransformingSupplyChains(): string {
    return this.definitions.TransformingSupplyChains;
  }

  public get Values(): string {
    return this.definitions.Values;
  }

  public get Version1(): string {
    return this.definitions.Version1;
  }

  public get Version3(): string {
    return this.definitions.Version3;
  }

  public get ViewPositions(): string {
    return this.definitions.ViewPositions;
  }

  public get WelcomeToTheFuture(): string {
    return this.definitions.WelcomeToTheFuture;
  }

  public get WereHiringJoin(): string {
    return this.definitions.WereHiringJoin;
  }

  public get WereMoreThan(): string {
    return this.definitions.WereMoreThan;
  }

  public get WhatDrivesUs(): string {
    return this.definitions.WhatDrivesUs;
  }

  public get WhatWereAbout(): string {
    return this.definitions.WhatWereAbout;
  }

  public get WhiteGlove(): string {
    return this.definitions.WhiteGlove;
  }

  public get WorldsFastestGrowingMarketplace(): string {
    return this.definitions.WorldsFastestGrowingMarketplace;
  }
}
