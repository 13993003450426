import { unreachable } from '@/src/utils/typeSafe';
import { numberFromKey, stringFromKey } from './utils';
import { DropDownOption } from '@/src/bits/forms/fields/dropdowns/types';

export class Attribute {
  description = '';
  id = 0;
  name = '';
  slug = '';

  constructor(args: Partial<Attribute> = {}) {
    Object.assign(this, args);
  }

  toSerializable(): AttributeSerializable {
    return {
      description: this.description,
      id: this.id,
      name: this.name,
      slug: this.slug
    };
  }

  //region static members
  static anyToDto(a: any): Attribute {
    const s = Attribute.anyToSerializable(a);
    return new Attribute(s);
  }

  static anyToSerializable(a: any): AttributeSerializable {
    return {
      description: stringFromKey('description', a),
      id: numberFromKey('id', a),
      name: stringFromKey('name', a),
      slug: stringFromKey('slug', a)
    };
  }
  //endregion
}

const asEmpty = { ...new Attribute() };
export type AttributeSerializable = typeof asEmpty;

export class Address {
  country = '';

  toSerializable(): AddressSerializable {
    return {
      country: this.country
    };
  }

  constructor(args: Partial<Address> = {}) {
    Object.assign(this, args);
  }

  static anyToDto(a: any): Address {
    const ps = Address.anyToSerializable(a);
    return new Address(ps);
  }

  static anyToSerializable(a: any): AddressSerializable {
    return {
      country: stringFromKey('country', a)
    };
  }
}

const as = { ...new Address() };
export type AddressSerializable = typeof as;

export const Condition = {
  Used: 1,
  Refurbished: 2,
  New: 3,
  PartsTool: 4
} as const;
export type Condition = (typeof Condition)[keyof typeof Condition];

export const conditionToText = (condition: Condition): string => {
  switch (condition) {
    case Condition.Used:
      return 'Used';
    case Condition.Refurbished:
      return 'Refurbished';
    case Condition.New:
      return 'New';
    case Condition.PartsTool:
      return 'Parts Tool';
    default:
      return unreachable(condition);
  }
};

export const conditions: { id: Condition; label: string }[] = [
  { id: Condition.New, label: conditionToText(Condition.New) },
  { id: Condition.Refurbished, label: conditionToText(Condition.Refurbished) },
  { id: Condition.PartsTool, label: conditionToText(Condition.PartsTool) },
  { id: Condition.Used, label: conditionToText(Condition.Used) }
];

export const conditionOptions: DropDownOption[] = conditions.map((e) => ({ id: `${e.id}`, label: e.label }));

// Necessary for id value that comes back as string from the Select control
export function idToCondition(a: string): Condition {
  return conditions.find((v) => v.id === parseInt(a))?.id || Condition.Used;
}

export const Priority = {
  Automated: -1,
  Low: 1,
  Medium: 2,
  High: 3
} as const;
export type Priority = (typeof Priority)[keyof typeof Priority];

export const priorities: { id: Priority; label: string }[] = [
  { id: Priority.Automated, label: 'Automated' },
  { id: Priority.Low, label: 'Low' },
  { id: Priority.Medium, label: 'Medium' },
  { id: Priority.High, label: 'High' }
];

export const WaferSize = {
  TwoInch: 2,
  ThreeInch: 3,
  FourInch: 4,
  FiveInch: 5,
  SixInch: 6,
  EightInch: 8,
  TwelveInch: 12,
  FifteenInch: 15
} as const;
export type WaferSize = (typeof WaferSize)[keyof typeof WaferSize];

export const waferSizeToText = (waferSize: WaferSize): string => {
  switch (waferSize) {
    case WaferSize.TwoInch:
      return '2"/50mm';
    case WaferSize.ThreeInch:
      return '3"/75mm';
    case WaferSize.FourInch:
      return '4"/100mm';
    case WaferSize.FiveInch:
      return '5"/125mm';
    case WaferSize.SixInch:
      return '6"/150mm';
    case WaferSize.EightInch:
      return '8"/200mm';
    case WaferSize.TwelveInch:
      return '12"/300mm';
    case WaferSize.FifteenInch:
      return '15"/450mm';
    default:
      return unreachable(waferSize);
  }
};

export const waferSizes: { id: WaferSize; label: string }[] = [
  { id: WaferSize.TwoInch, label: waferSizeToText(WaferSize.TwoInch) },
  { id: WaferSize.ThreeInch, label: waferSizeToText(WaferSize.ThreeInch) },
  { id: WaferSize.FourInch, label: waferSizeToText(WaferSize.FourInch) },
  { id: WaferSize.FiveInch, label: waferSizeToText(WaferSize.FiveInch) },
  { id: WaferSize.SixInch, label: waferSizeToText(WaferSize.SixInch) },
  { id: WaferSize.EightInch, label: waferSizeToText(WaferSize.EightInch) },
  { id: WaferSize.TwelveInch, label: waferSizeToText(WaferSize.TwelveInch) },
  { id: WaferSize.FifteenInch, label: waferSizeToText(WaferSize.FifteenInch) }
];

export const waferSizeOptions: DropDownOption[] = waferSizes.map((e) => ({ id: `${e.id}`, label: e.label }));
