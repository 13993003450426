import { FC } from 'react';
import { AuthMakeOfferModal } from './authMakeOffer';
import { AnonMakeOfferModal } from './anonMakeOffer';
import { userIsLoggedIn } from '@/src/state/user/selectors';
import { useAppSelector } from '@/src/store';

export const MakeOfferModal: FC = () => {
  const isLoggedIn = useAppSelector(userIsLoggedIn);

  return isLoggedIn ? <AuthMakeOfferModal /> : <AnonMakeOfferModal />;
};
