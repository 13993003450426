import {
  Attribute,
  AttributeSerializable,
  Condition,
  WaferSize,
  boolFromKey,
  conditionToText,
  dateToShort,
  numberFromKey,
  stringFromKey,
  waferSizeToText,
  AddressSerializable,
  Address
} from '../common/lib';
import { unreachable } from '../utils/typeSafe';
import { ToolStatus2String } from '../utils/strings/toolStatus2String';
import { captureException } from '@sentry/nextjs';

export const ToolStatus = {
  /** Installed / Operational (facilitized) */
  NO_STATUS: 0,
  /** Installed / Running */
  INSTALLED_RUNNING: 11,
  /** Installed - Idle */
  INSTALLED_IDLE: 12,
  /** Installed - Down */
  INSTALLED_DOWN: 13,
  /** Deinstalled */
  DEINSTALLED: 14,
  /** Deinstalled - Uncrated */
  DEINSTALLED_UNCRATED: 15,
  /** Deinstalled - Crated */
  DEINSTALLED_CRATED: 16,
  /** Deinstalled - Palletized */
  DEINSTALLED_PALLETIZED: 17
} as const;
export type ToolStatus = (typeof ToolStatus)[keyof typeof ToolStatus];

export const argIsToolStatus = (arg: unknown): arg is ToolStatus => Object.values(ToolStatus).includes(arg as ToolStatus);

// Tool statuses from 1-10 are legacy values that should be considered empty
export const isEmptyToolStatus = (arg: number): boolean => arg >= 0 && arg <= 10;

// create an object with only Dto properties / no functions or getters
export const Status = {
  Active: 100,
  Disabled: 200,
  Sold: 201,
  Deleted: 300
} as const;
export type Status = (typeof Status)[keyof typeof Status];

interface ModelConfigQuestionSerializable {
  id: number;
  listing_id: number;
  question_label: string;
  answer: string;
}

export class ModelConfigQuestion {
  id = 0;
  listing_id = 0;
  question_label = '';
  answer = '';

  constructor(args: Partial<ModelConfigQuestion> = {}) {
    Object.assign(this, args);
  }

  toSerializable(): ModelConfigQuestionSerializable {
    return {
      id: this.id,
      listing_id: this.listing_id,
      question_label: this.question_label,
      answer: this.answer
    };
  }

  //region Static Members
  static anyToDto(a: any): ModelConfigQuestion {
    const s = ModelConfigQuestion.anyToSerializable(a);
    return new ModelConfigQuestion(s);
  }
  // create an object with only Dto properties / no functions or getters
  static anyToSerializable(a: any): ModelConfigQuestionSerializable {
    return {
      id: numberFromKey('id', a),
      listing_id: numberFromKey('listing_id', a),
      question_label: stringFromKey('question_label', a),
      answer: stringFromKey('answer', a)
    };
  }

  static serializeWithoutThrowing(a: any): ModelConfigQuestionSerializable[] {
    let serializedList: ModelConfigQuestionSerializable[] = [];
    try {
      serializedList = a.map((e: any) => ModelConfigQuestion.anyToSerializable(e));
    } catch (e) {
      captureException(e);
      return [];
    }
    return serializedList;
  }
  //endregion
}

export const isListing2 = (value: unknown): value is Listing2 => {
  if (value && typeof value === 'object' && value.constructor.name === Listing2.name) {
    return true;
  }
  return false;
};

export class Listing2 {
  //region Properties
  id = 0;
  key = '';
  status: Status = Status.Active;
  private = 0;
  verified_at_ts = 0;
  condition: Condition = Condition.Used;
  vintage: number | null = null;
  price = 0;
  quantity = 1;
  created_at = '';
  category = new Attribute();
  make = new Attribute();
  model = new Attribute();
  wafer_size = '';
  wafer_size_values: number[] = [];
  disabled = 0;
  remarketing_slug = '';
  serial_number = '';
  tool_status: ToolStatus = ToolStatus.INSTALLED_IDLE;
  description = '';
  configuration = '';
  updated_at = '';
  files: File[] = [];
  photos: Photo[] = [];
  question_count = 0;
  model_config_questions: ModelConfigQuestion[] = [];
  marketing_price = '0';
  current_user_is_owner = false;
  address_known = false;
  address: Address | null = new Address();
  equipment_id = 0;
  //endregion

  constructor(args: Partial<Listing2> = {}) {
    Object.assign(this, args);
  }

  //region Getters

  get createdAtText(): string {
    return dateToShort(new Date(this.created_at));
  }

  get conditionText(): string {
    return conditionToText(this.condition);
  }

  get displayPhoto(): Photo | undefined {
    return [...this.photos].sort((a, b) => a.order - b.order)[0];
  }

  get statusText(): string {
    return Listing2.statusToText(this.status);
  }

  get toolStatusText(): string {
    return Listing2.toolStatusToText(this.tool_status);
  }

  get hasToolStatus(): boolean {
    return !isEmptyToolStatus(this.tool_status);
  }

  get isSold(): boolean {
    return this.status === Status.Sold;
  }

  get isActive(): boolean {
    return this.status === Status.Active;
  }

  get isEditable(): boolean {
    const editableStatuses: Status[] = [Status.Active, Status.Disabled];
    return editableStatuses.includes(this.status);
  }

  get allWaferSizeText(): string {
    return (this.wafer_size_values as WaferSize[]).map(waferSizeToText).join(', ');
  }

  get answeredModelConfigQuestions(): ModelConfigQuestion[] {
    return this.model_config_questions.filter((e) => Boolean(e.answer));
  }

  // return value as string and '' for 0 (api non value)
  get vintageText(): string {
    let r = `${this.vintage}`;
    if (!this.vintage || this.vintage === 0) r = '';
    return r;
  }

  get addressSafe(): Address {
    return this.address || new Address();
  }

  formatCountryText(countryOptions: { id: string; label: string }[]): string {
    return countryOptions.find((e) => e.id === this.addressSafe.country)?.label || '';
  }
  //endregion

  toSerializable(): ListingSerializable {
    const files: FileSerializable[] = this.files.map((e: File) => e.toSerializable());
    const photos: PhotoSerializable[] = this.photos.map((e: Photo) => e.toSerializable());

    return {
      category: this.category.toSerializable(),
      condition: this.condition,
      key: this.key,
      private: this.private,
      verified_at_ts: this.verified_at_ts,
      configuration: this.configuration,
      created_at: this.created_at,
      description: this.description,
      files,
      id: this.id,
      price: this.price,
      disabled: this.disabled,
      updated_at: this.updated_at,
      make: this.make.toSerializable(),
      model: this.model.toSerializable(),
      photos,
      quantity: this.quantity,
      serial_number: this.serial_number,
      status: this.status,
      vintage: this.vintage,
      wafer_size: this.wafer_size,
      wafer_size_values: this.wafer_size_values,
      tool_status: this.tool_status,
      remarketing_slug: this.remarketing_slug,
      question_count: this.question_count,
      model_config_questions: this.model_config_questions,
      marketing_price: this.marketing_price,
      current_user_is_owner: this.current_user_is_owner,
      address_known: this.address_known,
      address: this.address,
      equipment_id: this.equipment_id
    };
  }

  //region Static Members
  static anyToDto(a: any): Listing2 {
    const category = Attribute.anyToDto(a.category ?? {});
    const files = (a.files ?? []).map((e: any) => File.anyToDto(e));
    const make = Attribute.anyToDto(a.make ?? {});
    const model = Attribute.anyToDto(a.model ?? {});
    const address = a.address ? Address.anyToDto(a.address) : null;
    const photos = (a.photos ?? []).map((e: any) => Photo.anyToDto(e));
    const model_config_questions = (a.model_config_questions ?? []).map((e: any) => ModelConfigQuestion.anyToDto(e));
    const s = Listing2.anyToSerializable(a);

    return new Listing2({ ...s, category, files, make, model, photos, model_config_questions, address });
  }

  // create an object with only Dto properties / no functions or getters
  static anyToSerializable(a: any): ListingSerializable {
    // transform wafer_size into wafer_size_values

    const ws = stringFromKey('wafer_size', a);
    let wsv: number[] = [];
    if (ws !== '') wsv = ws.split(',').map((e) => Number.parseInt(e));

    return {
      wafer_size: ws,
      key: stringFromKey('key', a),
      private: numberFromKey('private', a),
      verified_at_ts: numberFromKey('verified_at_ts', a),
      // WARNING: price is actually coming back as a string from the API
      // using numberFromKey is due to transforming it and re-using this function
      // .trim throws in stringFromKey on subsequent uses of this function
      price: parseInt(numberFromKey('price', a).toString()),
      disabled: numberFromKey('disabled', a),
      updated_at: stringFromKey('updated_at', a),
      category: Attribute.anyToSerializable(a.category ?? {}),
      condition: numberFromKey('condition', a) as Condition,
      configuration: stringFromKey('configuration', a),
      created_at: stringFromKey('created_at', a),
      description: stringFromKey('description', a),
      files: (a.files ?? []).map((e: any) => File.anyToSerializable(e)),
      id: numberFromKey('id', a),
      make: Attribute.anyToSerializable(a.make ?? {}),
      model: Attribute.anyToSerializable(a.model ?? {}),
      photos: (a.photos ?? []).map((e: any) => Photo.anyToSerializable(e)),
      quantity: numberFromKey('quantity', a),
      serial_number: stringFromKey('serial_number', a),
      status: numberFromKey('status', a) as Status,
      vintage: numberFromKey('vintage', a),
      wafer_size_values: wsv.sort((a, b) => a - b),
      tool_status: numberFromKey('tool_status', a) as ToolStatus,
      question_count: a.question_count,
      remarketing_slug: stringFromKey('remarketing_slug', a),
      model_config_questions: ModelConfigQuestion.serializeWithoutThrowing(a.model_config_questions),
      marketing_price: stringFromKey('marketing_price', a),
      current_user_is_owner: boolFromKey('current_user_is_owner', a),
      address_known: boolFromKey('address_known', a),
      address: a.address ? Address.anyToSerializable(a.address) : null,
      equipment_id: numberFromKey('equipment_id', a)
    };
  }

  static statusToText(status: Status): string {
    switch (status) {
      case Status.Active:
        return 'Active';
      case Status.Disabled:
        return 'Disabled';
      case Status.Sold:
        return 'Sold';
      case Status.Deleted:
        return 'Deleted';
      default:
        return unreachable(status);
    }
  }

  static toolStatusToText = ToolStatus2String;

  static statusStringToText(status: string): string {
    switch (status) {
      case Status.Active.toString():
        return 'Active';
      case Status.Disabled.toString():
        return 'Disabled';
      case Status.Sold.toString():
        return 'Sold';
      case Status.Deleted.toString():
        return 'Deleted';
      default:
        return '';
    }
  }

  //endregion
}

export const toolStatuses: { id: ToolStatus; label: string }[] = [
  {
    id: ToolStatus.INSTALLED_RUNNING,
    label: Listing2.toolStatusToText(ToolStatus.INSTALLED_RUNNING)
  },
  {
    id: ToolStatus.INSTALLED_IDLE,
    label: Listing2.toolStatusToText(ToolStatus.INSTALLED_IDLE)
  },
  {
    id: ToolStatus.INSTALLED_DOWN,
    label: Listing2.toolStatusToText(ToolStatus.INSTALLED_DOWN)
  },
  {
    id: ToolStatus.DEINSTALLED,
    label: Listing2.toolStatusToText(ToolStatus.DEINSTALLED)
  },
  {
    id: ToolStatus.DEINSTALLED_UNCRATED,
    label: Listing2.toolStatusToText(ToolStatus.DEINSTALLED_UNCRATED)
  },
  {
    id: ToolStatus.DEINSTALLED_CRATED,
    label: Listing2.toolStatusToText(ToolStatus.DEINSTALLED_CRATED)
  },
  {
    id: ToolStatus.DEINSTALLED_PALLETIZED,
    label: Listing2.toolStatusToText(ToolStatus.DEINSTALLED_PALLETIZED)
  }
];

export const toolStatusOptions = toolStatuses.map((e) => ({ id: `${e.id}`, label: e.label }));

// Necessary for id value that comes back as string from the Select control
export function idToToolStatus(toolStatusId: string): ToolStatus {
  return toolStatuses.find((v) => v.id === parseInt(toolStatusId))?.id || ToolStatus.INSTALLED_IDLE;
}

export type ListingSerializable = {
  readonly id: number;
  readonly key: string;
  readonly status: Status;
  readonly private: number;
  readonly verified_at_ts: number;
  readonly condition: Condition;
  readonly vintage: number | null;
  readonly price: number;
  readonly quantity: number;
  readonly created_at: string;
  readonly category: AttributeSerializable;
  readonly make: AttributeSerializable;
  readonly model: AttributeSerializable;
  readonly wafer_size: string;
  readonly wafer_size_values: number[];
  readonly disabled: number;
  readonly remarketing_slug?: string;
  readonly serial_number: string | '';
  readonly tool_status: ToolStatus;
  readonly description: string;
  readonly configuration: string;
  readonly updated_at: string;
  readonly files: FileSerializable[];
  readonly photos: PhotoSerializable[];
  readonly question_count?: number;
  readonly model_config_questions: ModelConfigQuestionSerializable[];
  readonly marketing_price: string;
  readonly current_user_is_owner: boolean;
  readonly address_known: boolean;
  readonly address: AddressSerializable | null;
  readonly equipment_id: number;
};

export class File {
  url = '';
  id = 0;
  name = '';

  constructor(args: Partial<File> = {}) {
    Object.assign(this, args);
  }

  toSerializable(): FileSerializable {
    return {
      url: this.url,
      id: this.id,
      name: this.name
    };
  }

  //region Static Members
  static anyToDto(a: any): File {
    const s = File.anyToSerializable(a);
    return new File(s);
  }
  // create an object with only Dto properties / no functions or getters
  static anyToSerializable(a: any): FileSerializable {
    return {
      url: stringFromKey('url', a),
      id: numberFromKey('id', a),
      name: stringFromKey('name', a)
    };
  }
  //endregion
}

// this approach to creating the serializable type only works for simple structures.  It does not work for nested structures.
const fs = { ...new File() };
export type FileSerializable = typeof fs;

export class Photo {
  content_type = '';
  id = 0;
  large_url = '';
  medium_url = '';
  name = '';
  order = 0;
  small_url = '';

  toSerializable(): PhotoSerializable {
    return {
      content_type: this.content_type,
      id: this.id,
      large_url: this.large_url,
      medium_url: this.medium_url,
      name: this.name,
      order: this.order,
      small_url: this.small_url
    };
  }

  constructor(args: Partial<Photo> = {}) {
    Object.assign(this, args);
  }

  static anyToDto(a: any): Photo {
    const ps = Photo.anyToSerializable(a);
    return new Photo(ps);
  }

  static anyToSerializable(a: any): PhotoSerializable {
    return {
      content_type: stringFromKey('content_type', a),
      id: numberFromKey('id', a),
      large_url: stringFromKey('large_url', a),
      medium_url: stringFromKey('medium_url', a),
      name: stringFromKey('name', a),
      order: numberFromKey('order', a),
      small_url: stringFromKey('small_url', a)
    };
  }
}

const ps = { ...new Photo() };
export type PhotoSerializable = typeof ps;
