import { CloseIcon } from '../../svgs/svgs';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import TAILWIND_CSS_CONFIG from '../../../../tailwind.config';
import { LinearProgressBar } from '../../linearProgressBar';
import { IconButton, Typography } from '@mui/material';
import { Divider } from '../../dividers';

export interface WorkflowHeaderProps<TSteps extends number = number> {
  title: string;
  currentStep: TSteps;
  totalSteps: TSteps;
  setCurrentStep: (step: TSteps) => void;
  closeModal: () => void;
  onBackButtonClick?: () => void;
  displayProgressBar?: boolean;
  displayBackButton?: boolean;
  progressBarColor?: string;
  progressBackgroundColor?: string;
}

/**
 * TODO: ENG-93 - Make this component more flexible to allow for different format configurations
 */
export const WorkflowHeader = function <TSteps extends number = number>({
  currentStep,
  closeModal,
  title,
  totalSteps,
  displayProgressBar,
  onBackButtonClick,
  progressBackgroundColor,
  progressBarColor,
  setCurrentStep,
  displayBackButton = true
}: WorkflowHeaderProps<TSteps>) {
  return (
    <div>
      <div className="flex items-center justify-between px-2 py-2 lg:p-4">
        <div className="w-10">
          {displayBackButton && currentStep > 1 && (
            <IconButton
              data-testid="workflow_modal_back_button"
              color="inherit"
              onClick={() => {
                if (onBackButtonClick) {
                  onBackButtonClick();
                } else {
                  setCurrentStep((currentStep - 1) as TSteps);
                }
              }}
            >
              <ChevronLeftIcon color="inherit" />
            </IconButton>
          )}
        </div>
        <Typography variant="h6">{title}</Typography>
        <IconButton data-testid="close_workflow_model" onClick={() => closeModal()} color="inherit">
          <CloseIcon fill={TAILWIND_CSS_CONFIG.theme.colors['moov-blue']['500']} className="m-1.5 h-3 w-3" />
        </IconButton>
      </div>

      {displayProgressBar ? (
        <LinearProgressBar
          backgroundColor={progressBackgroundColor || TAILWIND_CSS_CONFIG.theme.colors['corp-gray']['100']}
          progressColor={progressBarColor || '#C72391'}
          percentage={Math.floor((currentStep / totalSteps) * 100)}
        />
      ) : (
        <Divider colorVariant="smokey-gray" />
      )}
    </div>
  );
};
