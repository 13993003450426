import { useState, useEffect } from 'react';
import { useUser } from '../useUser';
import {
  SIDEBAR_POSITION_TOP_CLASS,
  MIN_SIDEBAR_HEIGHT_CLASS,
  NAVBAR_HEIGHT_CLASS,
  MAX_SIDEBAR_HEIGHT_CLASS,
  NAVBAR_WITH_BANNER_HEIGHT_CLASS,
  SIDEBAR_POSITION_TOP_WITH_BANNER_CLASS,
  MIN_SIDEBAR_HEIGHT_WITH_BANNER_CLASS,
  MAX_SIDEBAR_HEIGHT_WITH_BANNER_CLASS,
  NAVBAR_HEIGHT_VALUE,
  NAVBAR_WITH_BANNER_HEIGHT_VALUE
} from './constants';

export const useInternalUserBanner = () => {
  const { user, userIsInternal } = useUser();
  const isInternal = userIsInternal(user);

  const [sidebarPositionTop, setSidebarPositionTop] = useState(SIDEBAR_POSITION_TOP_CLASS);
  const [minSidebarHeightClass, setMinSidebarHeightClass] = useState(MIN_SIDEBAR_HEIGHT_CLASS);
  const [navbarHeightClass, setNavbarHeightClass] = useState(NAVBAR_HEIGHT_CLASS);
  const [navbarHeightValue, setNavbarHeightValue] = useState(NAVBAR_HEIGHT_VALUE);
  const [maxSidebarHeightClass, setMaxSidebarHeightClass] = useState(MAX_SIDEBAR_HEIGHT_CLASS);

  useEffect(() => {
    if (isInternal) {
      setNavbarHeightClass(NAVBAR_WITH_BANNER_HEIGHT_CLASS);
      setNavbarHeightValue(NAVBAR_WITH_BANNER_HEIGHT_VALUE);
      setSidebarPositionTop(SIDEBAR_POSITION_TOP_WITH_BANNER_CLASS);
      setMinSidebarHeightClass(MIN_SIDEBAR_HEIGHT_WITH_BANNER_CLASS);
      setMaxSidebarHeightClass(MAX_SIDEBAR_HEIGHT_WITH_BANNER_CLASS);
    } else {
      navbarHeightClass != NAVBAR_HEIGHT_CLASS && setNavbarHeightClass(NAVBAR_HEIGHT_CLASS);
      navbarHeightClass != NAVBAR_HEIGHT_CLASS && setNavbarHeightValue(NAVBAR_HEIGHT_VALUE);
      sidebarPositionTop != SIDEBAR_POSITION_TOP_CLASS && setSidebarPositionTop(SIDEBAR_POSITION_TOP_CLASS);
      minSidebarHeightClass != MIN_SIDEBAR_HEIGHT_CLASS && setMinSidebarHeightClass(MIN_SIDEBAR_HEIGHT_CLASS);
      maxSidebarHeightClass != MAX_SIDEBAR_HEIGHT_CLASS && setMaxSidebarHeightClass(MAX_SIDEBAR_HEIGHT_CLASS);
    }
  }, [isInternal]);

  return { navbarHeightClass, navbarHeightValue, sidebarPositionTop, minSidebarHeightClass, maxSidebarHeightClass };
};
