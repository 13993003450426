import { colors, spacing } from '@/src/design/designConstants';
import { Modal, Stack, Typography } from '@mui/material';
import { closeModal } from '@/src/state/globalModal/slice';
import { useAppDispatch } from '@/src/store';
import { FAIcon } from '@/src/design/bits/fAIcon/fAIcon';
import { icons } from '@/src/design/bits/fAIcon/icons';
import { MobileMessages } from '@/src/messages/main/mobile';
import { MessagesMobileDrawer } from './messagesMobileDrawer';

export const MobileMessageCenter = () => {
  const dispatch = useAppDispatch();

  return (
    <>
      <Modal open={true}>
        <Stack height={'100%'} width="100%" bgcolor={colors.surface.white}>
          <Stack
            borderBottom={`2px solid ${colors.corpGray['100']}`}
            direction="row"
            alignItems={'center'}
            justifyContent={'space-between'}
            pl={spacing.xl}
          >
            {/* TODO: translations */}
            <Typography variant="subtitle3">Messages</Typography>
            <Stack p={spacing.xl} justifyContent={'center'} onClick={() => dispatch(closeModal())}>
              <FAIcon icon={icons.xMark} />
            </Stack>
          </Stack>
          <MobileMessages />
        </Stack>
      </Modal>
      <MessagesMobileDrawer />
    </>
  );
};
