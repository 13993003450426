import { UserFeatureSettings } from '@/src/entities/user/types';
import { AppState } from '../../store';
import { createSelector } from '@reduxjs/toolkit';

export const getCurrentUser = (state: AppState) => state.user.byId[state.user.currentId];
export const userIsLoading = (state: AppState) => state.user.loading;
export const userIsLoggedIn = (state: AppState) => state.user.loaded && !state.user.unauthenticated;
export const getCurrentUserFeatureSettings = createSelector([getCurrentUser], (user) => {
  return user?.feature_settings || new UserFeatureSettings({});
});

export const userIsInternal = createSelector([getCurrentUser], (user) => {
  return user && user.role >= 10;
});

export const userIsNotLoadingAndLoggedIn = createSelector([userIsLoading, userIsLoggedIn], (loading, loggedIn) => {
  return !loading && loggedIn;
});
