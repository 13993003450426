import type { FC, ReactNode } from 'react';

import { PageTitle } from '../bits/pageTitle';

interface Props {
  readonly className?: string;
  readonly id?: string;
  readonly title?: string;
}

interface WithActionChildrenProps extends Props {
  readonly leftActionChildren?: ReactNode;
  readonly actionChildren?: ReactNode;
  readonly childrenClassName?: string;
}

// -- #page wrappers (Used to give context and Y-padding to the page)
// -----------------------------------------------

export const PageContentID = 'page-content';

/**
 * Page wrapper with standard Y-padding.
 */
export const PageWrapperDeprecated: FC<Props> = ({ children, className = '' }) => (
  <div
    id={PageContentID}
    className={`flex flex-1 flex-col pb-page-b-mobile pt-page-t-mobile lg:pb-page-b-desktop lg:pt-page-t-desktop ${className}`}
  >
    {children}
  </div>
);

/**
 * Page wrapper with standard Y-padding. Use this for all pages going forward
 */
export const PageWrapper: FC<WithActionChildrenProps> = ({
  children,
  actionChildren,
  leftActionChildren,
  title,
  className = '',
  childrenClassName = ''
}) => (
  <div id={PageContentID} className={`flex h-full flex-1 flex-row  ${className}`}>
    <div className="h-full w-0 flex-1">
      {title && (
        <PageTitle title={title} leftChildren={leftActionChildren}>
          {actionChildren}
        </PageTitle>
      )}
      <div className={`page-wrapper-children-container ${childrenClassName}`}>{children}</div>
    </div>
  </div>
);

/**
 * Page wrapper with standard Y-padding for pages with breadcrumbs.
 */
export const PageWrapperWithBreadcrumbs: FC<Props> = ({ children, className = '' }) => (
  <div id={PageContentID} className={`flex flex-1 flex-col pb-page-b-mobile pt-page-t-mobile lg:pb-page-b-desktop ${className}`}>
    {children}
  </div>
);

/**
 * Page wrapper meant for custom Y-padding.
 */
export const PageWrapperCustom: FC<Props> = ({ children, className = '' }) => (
  <div id={PageContentID} className={`flex flex-1 flex-col ${className}`}>
    {children}
  </div>
);

// -- #content wrappers (Used to give context and X-padding to page content)
// -----------------------------------------------

/**
 * Content wrapper that takes up the entire width of the page.
 * Meant for backgrounds that expand past the standard width limit.
 */
export const FullWidthContentWrapper: FC<Props> = ({ children, className = '', id }) => (
  <div id={id} className={`relative w-full ${className}`}>
    {children}
  </div>
);

/**
 * Content wrapper with standard width and X-padding.
 */
export const ContentWrapper: FC<Props> = ({ children, className = '', id }) => (
  <div id={id} className={`relative mx-auto w-full max-w-standard-content px-content-x-mobile lg:px-content-x-desktop ${className}`}>
    {children}
  </div>
);

/**
 * Content wrapper with tighter width and X-padding.
 */
export const ContentWrapperTight: FC<Props> = ({ children, className = '', id }) => (
  <div id={id} className={`relative mx-auto w-full max-w-tight-content px-content-x-mobile lg:px-content-x-desktop ${className}`}>
    {children}
  </div>
);

/**
 * Content wrapper with standard width meant for custom X-padding.
 */
export const ContentWrapperCustom: FC<Props> = ({ children, className = '', id }) => (
  <div id={id} className={`relative mx-auto w-full max-w-standard-content ${className}`}>
    {children}
  </div>
);
