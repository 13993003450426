import { ConfirmationModal } from '../../../../bits/modals/confirmationModel';
import { useAppSelector } from '../../../../store';
import { getModalState } from '../../../../state/globalModal/selectors';

export const GlobalConfirmationModal = () => {
  const { modalProps } = useAppSelector(getModalState);

  if (!modalProps.confirmation) return null;

  const { title, description, onConfirm, onClose, confirmText, cancelText } = modalProps.confirmation;

  return (
    <ConfirmationModal
      isOpen={true}
      handleClose={onClose}
      handleConfirm={onConfirm}
      title={title}
      description={description}
      confirmText={confirmText}
      cancelText={cancelText}
    />
  );
};
