import { FC, HTMLAttributes, MouseEventHandler, ReactNode } from 'react';
import Link from 'next/link';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';

import type { Locale } from '../localization';
import { Url } from '../entities/common';

export interface LinkProps extends HTMLAttributes<HTMLAnchorElement> {
  readonly children: ReactNode;
  readonly href: Url;
  readonly openInNewTab?: boolean;
  readonly className?: string;
  readonly onClick?: MouseEventHandler<HTMLAnchorElement>;
  readonly locale?: Locale;
}

export const InternalLink: FC<LinkProps> = ({ children, href, openInNewTab, className, onClick, locale, ...props }) => (
  <Link href={href} locale={locale}>
    <a target={openInNewTab ? '_blank' : undefined} className={className} onClick={onClick} {...props}>
      {children}
    </a>
  </Link>
);

export const ExternalLink: FC<LinkProps> = ({ children, href, className, onClick, ...props }) => (
  <a href={href} className={className} onClick={onClick} {...props} target="_blank" rel="noreferrer">
    {children}
  </a>
);

export const LinkWithRightArrow: FC<LinkProps> = ({ children, href, className = '', ...props }) => (
  <InternalLink href={href} className={`flex w-fit items-center hover:underline ${className}`} {...props}>
    {children} <ChevronRightIcon />
  </InternalLink>
);

export const LinkWithLeftArrow: FC<LinkProps> = ({ children, href, className = '', ...props }) => (
  <InternalLink href={href} className={`flex w-fit items-center hover:underline ${className}`} {...props}>
    <ChevronLeftIcon /> {children}
  </InternalLink>
);
