import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { User, UserFeatureSettings } from '../../entities/user/types';

export interface UserState {
  currentId: string;
  byId: Record<string, User>;
  loading: boolean;
  loaded: boolean;
  error: boolean;
  unauthenticated: boolean;
  viewerCountryCode: string;
}

const initialState: UserState = {
  currentId: '',
  byId: {},
  loading: false,
  loaded: false,
  error: false,
  unauthenticated: false,
  // IP2Location returns '-' for unknown countries; initialize with '-' for consistency
  viewerCountryCode: '-'
};

export const slice = createSlice({
  name: 'users',
  initialState,
  reducers: {
    fetchUser: (state) => {
      state.loading = true;
      state.loaded = false;
      state.error = false;
    },
    loadUser: (state, action: PayloadAction<User>) => {
      state.byId = { ...state.byId, [action.payload.id]: action.payload };
      state.currentId = action.payload.id.toString();
      state.loading = false;
      state.loaded = true;
      state.error = false;
      state.unauthenticated = false;
    },
    loadUserError: (state) => {
      state.loading = false;
      state.loaded = false;
      state.error = true;
    },
    loadUserFeatureSettings: (state, action: PayloadAction<UserFeatureSettings>) => {
      state.byId[state.currentId].feature_settings = action.payload;
    },
    loadViewerCountryCode: (state, action: PayloadAction<string>) => {
      state.viewerCountryCode = action.payload;
    },
    userUnauthenticated: (state) => {
      state.unauthenticated = true;
      state.loading = false;
      state.loaded = false;
      state.error = false;
    }
  }
});

export const { loadUser, fetchUser, loadUserError, userUnauthenticated, loadViewerCountryCode } = slice.actions;

export default slice.reducer;

//
