import { ListingSummary, SuggestedListing } from '../entities/listing';
import { Equipment2 } from '../equipment/types';
import { getRemarketingInfoBySlug } from '../exclusives/selectors';
import { useAppSelector, AppState } from '../store';
import { Listing2 } from './types';
import { calcListingStrength, getListingStrengthColorsAndStage } from './utils';

const hasListingStrengthKey = (params: any): params is Equipment2 => {
  return params && typeof params === 'object' && 'listing_strength' in params;
};

export interface ListingStrengthParams {
  tool_status?: number;
  photos?: unknown[];
  configuration?: string;
  description?: string;
  wafer_size_values?: number[];
  vintage?: number | null;
  address?: { country: string } | null;
  condition?: number;
}

export const useListingStrength = (params: ListingStrengthParams) => {
  const percentage = calcListingStrength(params);
  // TODO: Only use listing_strength key in ENG-939, do not calculate this on the FE anymore
  const listingStrengthPercentage = hasListingStrengthKey(params) && params.listing_strength;
  const { hexColorCode, stage } = getListingStrengthColorsAndStage(percentage);

  return {
    percentage: listingStrengthPercentage || percentage,
    hexColorCode,
    stage
  };
};

export const useDisplayMarketingPrice = (listing: Listing2 | SuggestedListing | ListingSummary) => {
  const remarketingInfo = useAppSelector((state: AppState) => getRemarketingInfoBySlug(state, listing.remarketing_slug));

  return {
    shouldDisplayMarketingPrice: remarketingInfo && parseInt(listing.marketing_price) > 0
  };
};
