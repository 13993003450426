// a custom event type and a typed payload for the event
const loginMaskClick = 'register-login-mask-click';
export enum loginMaskClickEnum {
  LISTING_PRICE = 'listing-price',
  LISTING_LOGISTICS = 'listing-logistics'
} // use this enum to pass in the typed parameter for a loginMaskClick event

// this is the set that requires payloads of a specific shape in addition to the event name
export const typedRegisterEvents = {
  loginMaskClick: loginMaskClick
} as const;

// these dont need any other information besides the event name for fullstory
export const untypedRegisterEvents = {
  headerClick: 'register-header-click',
  accountFinish: 'register-account-finish-click',
  companyFinish: 'register-company-finish-click',
  loginModalClick: 'register-login-modal-click'
} as const;

export enum ExistingUserRegisterAccount {
  REGISTER_EXISTING_ACCOUNT_PROMPTED = 'register-existing-account-prompted',
  REGISTER_EXISTING_ACCOUNT_DISMISSED = 'register-existing-account-dismissed',
  REGISTER_EXISTING_ACCOUNT_SUBMITTED = 'register-existing-account-submitted'
}

// typing for all basic events without payload
const registerValues = Object.values(untypedRegisterEvents);
type RegisterValuesType = [(typeof registerValues)[number], any] | [(typeof registerValues)[number]];

// typing for all events that require payloads
const loginMaskClickEnumValues = Object.values(loginMaskClickEnum);
const loginMaskClickTypes = [...loginMaskClickEnumValues] as const;
type RegisterLoginMaskClickType = [typeof loginMaskClick, { type: (typeof loginMaskClickTypes)[number] }];

type ClaimAccountEvents = [ExistingUserRegisterAccount, { email: string }];

// The end result of all possible events and their payloads
export type RegisterTypes = RegisterValuesType | RegisterLoginMaskClickType | ClaimAccountEvents;
