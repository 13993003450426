import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';
import { CombinedState, Reducer, combineReducers, configureStore } from '@reduxjs/toolkit';

import listingsReducer, { ListingsState } from './listings/state/slice';
import unconfirmedListingsReducer, { UnconfirmedListingsState } from './state/unconfirmedListings/slice';
import usersReducer, { UserState } from './state/user/slice';
import categoriesReducer, { CategoryState } from './state/category/slice';
import makesReducer, { MakeState } from './state/makes/slice';
import modelsReducer, { ModelState } from './state/models/slice';
import requestsReducer2, { State as Requests2State } from './requests2/slice';
import equipmentReducer2, { State as Equipment2State } from './equipment/slice';
import modalReducer, { ModalState } from './state/globalModal/slice';
import alertBannerReducer, { GlobalAlertBannerState } from './state/banner/slice';
import exclusivesReducer, { State as ExclusivesState } from './exclusives/slice';
import searchReducer, { SearchState } from './marketplace/search/state/slice';
import logisticsReducer, { LogisticsState } from './state/logistics/slice';
import routerReducer, { RouterState } from './state/router/slice';
import metaReducer, { MetaState } from './state/meta/slice';
import modelConfigQuestionsReducer, { ModelConfigQuestionState } from './state/modelConfigQuestions/slice';
import configAnswersReducer, { ConfigAnswerState } from './state/configAnswers/slice';
import messagesReducer, { MessagesState } from './messages/slice';

/*  Redux Toolkit's configureStore API should not need any additional typings. We want to extract the RootState type
    and the Dispatch type so that they can be referenced as needed. Inferring these types from the store itself means
    that they correctly update as you add more state slices or modify middleware settings.
    Since those are types, it's safe to export them directly from your store setup file such as app/store.ts
    and import them directly into other files.  */

const appReducer = combineReducers({
  listings: listingsReducer,
  unconfirmedListings: unconfirmedListingsReducer,
  user: usersReducer,
  requests2: requestsReducer2,
  equipment2: equipmentReducer2,
  modal: modalReducer,
  alertBanner: alertBannerReducer,
  exclusives: exclusivesReducer,
  categories: categoriesReducer,
  makes: makesReducer,
  models: modelsReducer,
  search: searchReducer,
  logistics: logisticsReducer,
  router: routerReducer,
  meta: metaReducer,
  modelConfigQuestions: modelConfigQuestionsReducer,
  configAnswers: configAnswersReducer,
  messages: messagesReducer
});

// This is used to reset the store on logout or login so that different user data is not persisted
const rootReducer = (state: AppState, action: { type: 'root/reset' }): AppState => {
  if (action.type === 'root/reset') {
    // publicStores is for stores that we don't want to reset on logout like the exclusives which populates
    // the exclusives browse dropdown in the header
    // Do not add any stores that contain user data to publicStores
    const publicStores = {
      exclusives: state.exclusives,
      categories: state.categories,
      router: state.router,
      meta: state.meta
    } as unknown as AppState;
    state = appReducer(publicStores, { type: 'root/reset' });
  }
  return appReducer(state, action);
};

// Using AppState causes circular reference chaos
type STATEWORKAROUND = Reducer<
  CombinedState<{
    listings: ListingsState;
    unconfirmedListings: UnconfirmedListingsState;
    user: UserState;
    requests2: Requests2State;
    equipment2: Equipment2State;
    modal: ModalState;
    alertBanner: GlobalAlertBannerState;
    exclusives: ExclusivesState;
    categories: CategoryState;
    makes: MakeState;
    models: ModelState;
    search: SearchState;
    logistics: LogisticsState;
    router: RouterState;
    meta: MetaState;
    modelConfigQuestions: ModelConfigQuestionState;
    configAnswers: ConfigAnswerState;
    messages: MessagesState;
  }>
>;

export const getNewStore = () =>
  configureStore({
    reducer: rootReducer as STATEWORKAROUND
  });

const store = getNewStore();

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;

// Inferred type: {listings: ListingsState}
export type AppDispatch = typeof store.dispatch;
export type AppState = ReturnType<typeof appReducer>;

// Use throughout the app instead of plain `useDispatch` and `useSelector`
export const useAppDispatch = () => useDispatch<AppDispatch>();

export const useAppSelector: TypedUseSelectorHook<AppState> = useSelector;

export default store;
