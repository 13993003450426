// THIS IS GENERATED CODE: DO NOT MODIFY
// Please run `ing flag codegen` to regenerate this file.
//
// See https://github.com/moovco/ing for more information

export enum BooleanFlag {
  Remarketing = 'remarketing',
  NewSearch = 'newSearch',
  LogisticsCalculator = 'logisticsCalculator',
  Messaging = 'messaging'
}

export enum NumberFlag {}

export enum StringFlag {
  MoovCoMaintenanceMessage = 'moovCoMaintenanceMessage'
}

export const getDefaultBoolean = (arg: BooleanFlag): boolean => {
  switch (arg) {
    case BooleanFlag.Remarketing:
      return false;
    case BooleanFlag.NewSearch:
      return false;
    case BooleanFlag.LogisticsCalculator:
      return false;
    case BooleanFlag.Messaging:
      return false;
    default:
      return unreachable(arg);
  }
};

// TODO: reimplement this function when number flags are added
// export const getDefaultNumber = (arg: NumberFlag): number => {
//   switch (arg) {
//     default:
//       return unreachable(arg);
//   }
// };

export const getDefaultString = (arg: StringFlag): string => {
  switch (arg) {
    case StringFlag.MoovCoMaintenanceMessage:
      return '';
    default:
      return unreachable(arg);
  }
};

const unreachable = (_error: never) => {
  throw new Error('Unreachable!') as never;
};
