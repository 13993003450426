import axios from 'axios';
import { AppDispatch, AppState } from '../store';
import { loadMessages, setError, setLoading, setUnreadMessageCount, updateThread, selectMessageOnLoad } from './slice';
import { ERROR_MESSAGES, MOOV_API_URL } from '../constants';
import { captureException } from '@sentry/nextjs';
import { openError } from '../state/banner/slice';
import { getCurrentThread } from './selectors';
import { MessageThread, MessageThreadSerializable, Message } from './types';
import { valueFromKey } from '../common/lib';

export function fetchAllMessages(
  { setLoadFlags, selectOnLoad }: { setLoadFlags?: boolean; selectOnLoad?: boolean } = { setLoadFlags: true, selectOnLoad: true }
) {
  return async (dispatch: AppDispatch) => {
    setLoadFlags && dispatch(setLoading());

    // Update the unread count so messages/count are not out of sync
    dispatch(getUnreadMessageCount());
    return axios
      .get<{ data: MessageThread[] }>(`${MOOV_API_URL}/auth/messages`, {
        headers: { 'Content-Type': 'application/vnd.api+json' },
        withCredentials: true
      })
      .then((r) => {
        const threads = valueFromKey<MessageThreadSerializable[]>('data', r.data);
        dispatch(loadMessages(threads.map((thread) => MessageThread.anyToSerializable(thread))));
        selectOnLoad && dispatch(selectMessageOnLoad());
      })
      .catch((e) => {
        dispatch(setError());
        captureException(e);
        dispatch(
          openError({
            error: ERROR_MESSAGES.REFRESH
          })
        );
      });
  };
}

export function createMessageForCurrentThread(body: string) {
  return async (dispatch: AppDispatch, getState: () => AppState) => {
    const currentThread = getCurrentThread(getState());

    const optimisticUpdate = MessageThread.anyToSerializable({
      ...currentThread,
      messages: [
        ...currentThread.messages,
        {
          body,
          created_at: new Date().toISOString(),
          sender_alias: 'You',
          seen: true,
          current_user_is_sender: true,
          key: `optimistic-message-update-${new Date().getTime()}`
        }
      ]
    });

    dispatch(updateThread(optimisticUpdate));

    return axios
      .post(
        `${MOOV_API_URL}/auth/messages/opportunity/${currentThread.key}`,
        { body },
        {
          headers: { 'Content-Type': 'application/vnd.api+json' },
          withCredentials: true
        }
      )
      .then((r) => {
        const apithreadUpdate = MessageThread.anyToSerializable({
          ...currentThread,
          messages: [...currentThread.messages, Message.anyToSerializable(r.data.data)]
        });

        dispatch(updateThread(apithreadUpdate));
      })
      .catch((e) => {
        dispatch(updateThread(currentThread.toSerializable()));
        dispatch(setError());
        captureException(e);
        dispatch(
          openError({
            error: ERROR_MESSAGES.REFRESH
          })
        );
      });
  };
}

export function markCurrentMessageThreadAsRead() {
  return async (dispatch: AppDispatch, getState: () => AppState) => {
    const currentThread = getCurrentThread(getState());

    return axios
      .put(
        `${MOOV_API_URL}/auth/messages/opportunity/${currentThread.key}/read`,
        {},
        {
          headers: { 'Content-Type': 'application/vnd.api+json' },
          withCredentials: true
        }
      )
      .then(() => {
        const apithreadUpdate = MessageThread.anyToSerializable({
          ...currentThread,
          messages: currentThread.messages.map((message) => Message.anyToSerializable({ ...message, seen: true }))
        });
        dispatch(updateThread(apithreadUpdate));

        // Update the unread count
        dispatch(getUnreadMessageCount());
      })
      .catch((e) => {
        captureException(e);
      });
  };
}

export function createMessageForListing({ body, listingKey }: { body: string; listingKey: string }) {
  return async (dispatch: AppDispatch, _getState: () => AppState) => {
    return axios
      .post<void>(
        `${MOOV_API_URL}/auth/messages/listing/${listingKey}`,
        { body },
        {
          headers: { 'Content-Type': 'application/vnd.api+json' },
          withCredentials: true
        }
      )
      .then(() => {})
      .catch((e) => {
        captureException(e);
        dispatch(
          openError({
            error: ERROR_MESSAGES.REFRESH
          })
        );
      });
  };
}

export function getUnreadMessageCount() {
  return async (dispatch: AppDispatch, _getState: () => AppState) => {
    return axios
      .get<{ data: number }>(`${MOOV_API_URL}/auth/messages/unread/count`, {
        headers: { 'Content-Type': 'application/vnd.api+json' },
        withCredentials: true
      })
      .then((resp) => {
        dispatch(setUnreadMessageCount(resp.data.data));
      })
      .catch((e) => {
        captureException(e);
      });
  };
}
