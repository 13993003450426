export const typedMessageEvent = {
  sendMessageFromMessageCenter: 'send-message-from-message-center',
  sendMessageSubmitFromModal: 'submit-send-message-to-seller',
  disMissSendingMessage: 'dismiss-sending-message'
} as const;

type SendMessageFromMessageCenterPage = [
  typeof typedMessageEvent.sendMessageFromMessageCenter,
  { message: string; opportunityKey: string }
];
type SendMessageFromListingPage = [typeof typedMessageEvent.sendMessageSubmitFromModal, { message: string; listingKey: string }];
type DisMissSendingMessage = [typeof typedMessageEvent.disMissSendingMessage, { listingKey: string }];

export type MessageTypes = SendMessageFromMessageCenterPage | SendMessageFromListingPage | DisMissSendingMessage;
