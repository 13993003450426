import { useEffect } from 'react';
import { useInterval } from '@/src/hooks/useInterval';
import { getUnreadMessageCount } from '@/src/messages/thunks';
import { useAppDispatch } from '@/src/store';

export const useFetchUnreadMessageCount = () => {
  const { setIntvl } = useInterval();
  const dispatch = useAppDispatch();
  useEffect(() => {
    //Fetch initial unread count
    dispatch(getUnreadMessageCount());

    //Fetch unread count every 2 minutes
    const interval = 2 * 60000;
    setIntvl(() => {
      dispatch(getUnreadMessageCount());
    }, interval);
  }, []);
};
